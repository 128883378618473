import React from "react";
import { Link } from "gatsby";

const ShortTitles = props => {
    return (
        <div class="jff-contributor-cards">
            <div class="jff-contributor-image-container">
                <Link to={props.blogContent.TitleLink}> <img src={props.blogContent.ImagePath} alt="avatar" /> </Link>
                <div class="jff-contributor-title-bar">
                    <Link to={props.blogContent.TitleLink}>
                        <h1 class="jff-contributor-title">{props.blogContent.Title}</h1>
                    </Link>
                </div>
            </div>
            <div class="jff-contributor-content">
                <div class="jff-contributor-content-name-bar">
                    <Link to={props.blogContent.TitleLink}>
                        <h1 class="jff-contributor-name">{props.blogContent.Title}</h1>
                    </Link>
                    <div class="jff-contributor-social-icons">
                        {props.blogContent.SocialLinks.map(item => (
                            <li>
                                <Link to={item.Link}> <i className={item.IconName}></i> </Link>
                            </li>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShortTitles;
